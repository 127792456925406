/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jc95_157 {
  display: flex;
}

._justify-content-space-between_1jc95_161 {
  justify-content: space-between;
}

._tabular-nums_1jc95_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1jc95_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jc95_157 {
  display: flex;
}

._justify-content-space-between_1jc95_161 {
  justify-content: space-between;
}

._tabular-nums_1jc95_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1jc95_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MembersTable_1jc95_355 {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
._MembersTable_1jc95_355 .ant-table-cell-fix-left {
  background-color: #fff;
}
._MembersTable_1jc95_355 .ant-pagination {
  display: none;
}
._MembersTable_1jc95_355 ._tagsCell_1jc95_367 {
  display: flex;
}
._MembersTable_1jc95_355 ._tagsCell_1jc95_367 ._addTagIcon_1jc95_370 {
  visibility: hidden;
}
._MembersTable_1jc95_355 ._tagsCell_1jc95_367:hover ._addTagIcon_1jc95_370 {
  visibility: visible;
}
._MembersTable_1jc95_355 ._clickableTag_1jc95_376 {
  cursor: pointer;
}
._MembersTable_1jc95_355 ._membersTableContainer_1jc95_379 {
  padding-right: 0.5rem;
}
._MembersTable_1jc95_355 .ant-table-thead > tr > th {
  white-space: normal;
}
._MembersTable_1jc95_355 .ant-table-tbody > tr:not(:first-child) > td {
  padding-top: 4.5px !important;
  padding-bottom: 4.5px !important;
}
._MembersTable_1jc95_355 ._loadSpinner_1jc95_389 {
  margin-top: 10%;
}
._MembersTable_1jc95_355 ._table_1jc95_392 {
  flex: 1;
  max-height: unset !important;
}
._MembersTable_1jc95_355 ._table_1jc95_392 > div > div > [class^=BodyRow_] {
  background: transparent;
}
._MembersTable_1jc95_355 ._tableHeader_1jc95_399 {
  padding: 0 1.5rem 0 2rem;
}

._checkboxHeaderCell_1jc95_403 {
  background-color: unset !important;
}

._checkboxCell_1jc95_407,
._checkboxHeaderCell_1jc95_403 {
  pointer-events: none;
}
._checkboxCell_1jc95_407 > div,
._checkboxHeaderCell_1jc95_403 > div {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  pointer-events: none;
}
._checkboxCell_1jc95_407 > div > label,
._checkboxHeaderCell_1jc95_403 > div > label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: initial;
}

._tag_1jc95_367 {
  cursor: pointer;
}

._defaultTag_1jc95_432 {
  background-color: #e5f7ff !important;
}

._fixedCell_1jc95_436 {
  overflow: hidden;
}

._leftAlignedButton_1jc95_440 {
  padding-left: 0;
}

._loadingOverlaySpinner_1jc95_444 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: white;
  opacity: 0.7;
}