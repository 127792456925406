._MessageComposer_iz0g8_1 {
  display: flex;
  flex-direction: column;
}
._MessageComposer_iz0g8_1 > * + * {
  margin-block: 25px;
}
._MessageComposer_iz0g8_1 ._EmailComposer_iz0g8_8 {
  min-height: 500px;
  border: 1px solid #DADCDE;
}
._MessageComposer_iz0g8_1 ._EmailComposer_iz0g8_8._hide_iz0g8_12 {
  display: none;
}